import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import {axisoReqWithAuthToken, delay} from "../Utils";

export default function Wallet({ balanceAndExposure, setBalanceAndExposure }) {
  const [isLoading, setIsLoading] = useState(false);

  const getWalletDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await axisoReqWithAuthToken.get("/client/wallet");
      setIsLoading(false);
      setBalanceAndExposure({ balance: data.balance, exposure: data.exposure });
    } catch (error) {
      setIsLoading(false);

      toast.error("Failed to update balance and exposure", {
        className: "toast-message",
      });
    }
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  return (
    !isLoading && (
      <div className="z-40 flex justify-between items-center bg-red-500 h-7 px-6 font-semibold max-sm:px-0">
        <p className="text-white  p-4 py-8">
          Balance: {balanceAndExposure.balance}
        </p>
        <p className="text-white  px-4 py-10">
          Exp: {balanceAndExposure.exposure}
        </p>
      </div>
    )
  );
}
