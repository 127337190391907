import React from "react";

export default function MarketAnalysisCard({
  leagueName,
  team1,
  team1Amount,
  team2,
  team2Amount,
}) {
  return (
      <div className="bg-white rounded h-fit shadow-xl min-w-[16rem]">
          <h3 className="px-3 py-2 bg-red-500 rounded text-white font-medium underline underline-offset-1 [word-spacing:0.2rem]">
              {leagueName} (MATCH ODDS)
          </h3>
          <div className="w-full flex align-center justify-between py-2 px-3 border-b-[1px]">
              <span>{team1}</span>
              <span
                  className={`font-bold ${
                      team1Amount > team2Amount
                          ? "text-red-700"
                          : "text-green-700"
                  }`}
              >
                  {!team1Amount
                      ? "0"
                      : (team1Amount > team2Amount ? "-" : "+") +
                        ` ${team1Amount}`}
              </span>
          </div>
          <div className="w-full flex align-center justify-between py-2 px-3 border-b-[1px]">
              <span>{team2}</span>
              <span
                  className={`font-bold ${
                      team2Amount > team1Amount
                          ? "text-red-700"
                          : "text-green-700"
                  }`}
              >
                  {!team2Amount
                      ? "0"
                      : (team2Amount > team1Amount ? "-" : "+") +
                        ` ${team2Amount}`}
              </span>
          </div>
      </div>
  );
}
