import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBar from "./SideBar";
import AdminSideBar from "./adminComponents/AdminSideBar";
import { Outlet, useNavigate } from "react-router-dom";
import { userTypes } from "../Utils";

export default function HomeComp({isLoggedIn, balanceAndExposure, setBalanceAndExposure}) {
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const userType = localStorage.getItem("userType");
  const username = localStorage.getItem("username");

  const navigate = useNavigate();

  const isAdmin = userType === userTypes.client ? false : true;

  const showSidebarHandler = () => {
    setShowSidebar(prev =>  !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 639) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/login");
  }, []);

  const renderBody = () => {
    if (isAdmin)
      return (
        <>
          {!isSmallScreen && (
            <div className="home">
              <AdminSideBar isSmallScreen={isSmallScreen} username={username} />
              <Outlet />
            </div>
          )}

          {isSmallScreen && (
            <div className="pb-16 mt-24">
              <AdminSideBar
                isSmallScreen={isSmallScreen}
                showSidebar={showSidebar}
                showSidebarHandler={showSidebarHandler}
                username = {username}
              />
              <Outlet />
            </div>
          )}
        </>
      );

    return (
      <>
        {!isSmallScreen && (
          <div className="home">
            <SideBar isSmallScreen={isSmallScreen} username={username} />
            <Outlet />
          </div>
        )}

        {isSmallScreen && (
          <div className="pb-16 mt-28">
            <SideBar
              isSmallScreen={isSmallScreen}
              showSidebar={showSidebar}
              showSidebarHandler={showSidebarHandler}
              username={username}
            />
            <Outlet />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="overflow-x-hidden">
      <Header
        username={username}
        showSidebarHandler={showSidebarHandler}
        isAdmin={isAdmin}
        isLoggedIn={isLoggedIn}
        balanceAndExposure={balanceAndExposure}
        setBalanceAndExposure={setBalanceAndExposure}
      />
      {renderBody()}
      <Footer />
    </div>
  );
}
