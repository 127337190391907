import React, { useState, useEffect } from "react";
import logo from "../images/ironManLogo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiTwotoneSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {axisoReqWithAuthToken, delay} from "../Utils";
import Wallet from "./Wallet";

export default function Header({
  username,
  showSidebarHandler,
  isAdmin,
  isLoggedIn,
  balanceAndExposure,
  setBalanceAndExposure,
}) {
  const [isSettingDivOpen, setIsSettingDivOpen] = useState(false);
  const [currentActive, setCurrentActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const hanldeUsername = () => {
    setIsSettingDivOpen((prev) => !prev);
    setCurrentActive(null);
  };

  const changePasswordHandler = () => {
    setCurrentActive("changePassword");
    navigate("/changePassword", { state: { username } });
  };

  const logoutHandler = async () => {
    setCurrentActive("logout");
    try {
      setIsLoading(true);
      await axisoReqWithAuthToken.post("/logout");
      localStorage.clear();
      toast.success("Logout Successful!", { className: "toast-message" });
      await delay(500);
      setIsLoading(false);
      navigate("/login");
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  return (
    <div className="fixed w-full z-30">
      {isLoggedIn && !isAdmin && (
        <Wallet
          balanceAndExposure={balanceAndExposure}
          setBalanceAndExposure={setBalanceAndExposure}
        />
      )}
      <header className="px-2 pt-2 pb-3 bg-white w-full flex justify-between items-center shadow-[-2px_2px_10px_rgba(0,0,0,0.3)] z-20">
        <GiHamburgerMenu
          size="2rem"
          className="block sm:hidden text-red-500 border-red-500"
          onClick={showSidebarHandler}
        />
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img
            src={logo}
            alt="Logo"
            className="w-12 h-12 mx-3 object-cover rounded-full drop-shadow-[0_7px_5px_rgba(255,0,0,0.5)]"
          />
          <h1 className="text-2xl font-bold hidden sm:block ">
            Ironman Toss Book
          </h1>
        </div>
        <div className="relative bg-red-500 p-2 text-center rounded-xl">
          <div
            className="mx-2 text-white font-semibold cursor-pointer"
            onClick={hanldeUsername}
          >
            {username}
          </div>
          <ul
            className={`bg-white border border-gray-200 rounded-lg shadow-lg bg-red-50 username ${
              isSettingDivOpen ? "username-transition" : ""
            }`}
          >
            <li className="block w-full">
              <div
                to="/changePassword"
                className={
                  "p-2 pl-4 hover:bg-red-500 hover:text-white cursor-pointer transition-li " +
                  `${currentActive === "changePassword" ? "active" : ""}`
                }
                onClick={changePasswordHandler}
              >
                <AiTwotoneSetting className="hover:text-white" />
                <span className="font-semibold">Change Password</span>
              </div>
            </li>
            <li className="block w-full">
              <div
                className={
                  "p-2 pl-4 hover:bg-red-500 hover:text-white cursor-pointer transition-li " +
                  `${currentActive === "logout" ? "active" : ""} ${
                    isLoading && "pointer-events-none"
                  }`
                }
                onClick={logoutHandler}
              >
                <FiLogOut className="hover:text-white" />
                <span className="font-semibold">Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}
