import React, { useState, useEffect } from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

export default function PaginationComponent({
  numberOfEntries,
  entriesPerPage,
  activePage,
  setActivePage,
}) {
  const [currentPageListStart, setCurrentPageListStart] = useState(0);

  const numPages = Math.ceil(numberOfEntries / entriesPerPage);
  const numsOfPageListToShow = 10;
  const currentPageListEnd =
    currentPageListStart + numsOfPageListToShow >= numPages
      ? numPages - 1
      : currentPageListStart + numsOfPageListToShow - 1;

  useEffect(() => {
    setActivePage(currentPageListStart);
  }, [currentPageListStart, setActivePage]);

  const paginationBtnHandler = (e) => {
    setActivePage(+e.target.dataset.pageNo);
  };

  const nextPageListShowHandler = () => {
    setCurrentPageListStart((prev) => currentPageListEnd + 1);
  };

  const renderPaginationButtons = () => {
    const paginationButtons = [];

    for (
      let i = 0;
      i < numsOfPageListToShow && i + currentPageListStart < numPages;
      i++
    )
      paginationButtons.push(
        <span
          key={i}
          className={`w-8 h-8 max-sm:w-6 max-sm:h-6 max-sm:text-sm text-center flex items-center justify-center rounded-2xl cursor-pointer ${
            i + currentPageListStart === activePage
              ? "bg-red-500 text-white font-bold"
              : "bg-white text-black"
          }`}
          data-page-no={i + currentPageListStart}
          onClick={paginationBtnHandler}
        >
          {currentPageListStart + i + 1}
        </span>
      );

    if (currentPageListStart + numsOfPageListToShow < numPages) {
      paginationButtons.push(
        <span
          key={"..."}
          className="cursor-pointer"
          onClick={nextPageListShowHandler}
        >
          ...
        </span>
      );
      paginationButtons.push(
        <span
          key={"last"}
          className="w-8 h-8 max-sm:w-6 max-sm:h-6 max-sm:text-sm text-center flex items-center justify-center rounded-2xl bg-white text-black"
        >
          {numPages}
        </span>
      );
    }

    return paginationButtons;
  };

  return (
    <div className="mx-auto mt-2 flex items-center justify-between gap-2 flex-wrap h-fit w-fit max-lg:w-10/12 bg-red-100 px-2 py-2 rounded-xl">
      <MdKeyboardDoubleArrowLeft
        className="text-red-500 text-2xl cursor-pointer"
        onClick={() =>
          setCurrentPageListStart((prev) => {
            return prev <= 0 ? 0 : prev - numsOfPageListToShow;
          })
        }
      />
      <MdOutlineArrowBackIosNew
        className="text-red-500 text-xl cursor-pointer"
        onClick={() =>
          setActivePage((prev) =>
            prev === currentPageListStart ? prev : prev - 1
          )
        }
      />
      {renderPaginationButtons()}
      <MdOutlineArrowForwardIos
        className="text-red-500 text-xl cursor-pointer"
        onClick={() =>
          setActivePage((prev) => (prev < currentPageListEnd ? prev + 1 : prev))
        }
      />
      <MdKeyboardDoubleArrowRight
        className="text-red-500 text-2xl cursor-pointer"
        onClick={() =>
          setCurrentPageListStart((prev) =>
            prev >= numPages - numsOfPageListToShow
              ? prev
              : prev + numsOfPageListToShow
          )
        }
      />
    </div>
  );
}
