import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import UserTableComponent from "../generalComponents/UserTableComponent";
import PaginationComponent from "../generalComponents/PaginationComponent";
import { getActivePageData, axisoReqWithAuthToken } from "../../Utils";
import { toast } from "react-toastify";
import LoadingComponent from "../generalComponents/LoadingComponent";

/**
 * Unblock user not actually unblocking user.
 */
export default function BlockedListComponent() {
  const [activePage, setActivePage] = useState(0);
  const [blockedUserList, setBlockedUserList] = useState([]);
  const [isBlockListUpdated, setIsblockListUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const entriesPerPage = 7;

  const navigate = useNavigate();

  const columns = ["UserName", "Unblock"];

  const getBlockedUserData = async () => {
    try {
      setIsLoading(true);
      setBlockedUserList([]);
      const {data} = await axisoReqWithAuthToken.get("/admin/getBlockedList");
      data.blockUserList.forEach(element => {
        setBlockedUserList(prev => [...prev, {username: element.userName, unblock: "Unblock"}])
      });
      setIsLoading(false);
    } catch(error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
      }
    }
  };

  useEffect(() => {
    getBlockedUserData();
  }, [isBlockListUpdated]);

  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && <div className="bg-white h-fit pb-4">
        <UserTableComponent
          tableName={"Blocked List"}
          columns={columns}
          data={getActivePageData(blockedUserList, entryStart, entryEnd)}
          setIsblockListUpdated={setIsblockListUpdated}
        />
        <PaginationComponent
          numberOfEntries={blockedUserList.length}
          entriesPerPage={entriesPerPage}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>}
    </>
  );
}
