import React, { useState, useEffect } from "react";
import CreateTable from "./CreateTable";
import PaginationComponent from "./generalComponents/PaginationComponent";
import LoadingComponent from "./generalComponents/LoadingComponent";
import { toast } from "react-toastify";
import { getActivePageData, axisoReqWithAuthToken, getTimeAMPMFormat, stringCapitalize } from "../Utils";

export default function TossTransaction() {
  const [activePage, setActivePage] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const entriesPerPage = 7;

  const columns = [
    { header: "No", field: "no" },
    { header: "League Name", field: "leagueName" },
    { header: "Match Name", field: "matchName" },
    { header: "Date & Time", field: "date" },
    { header: "Bet Team", field: "betTeam" },
    { header: "Amount", field: "amount" },
    { header: "Rate", field: "rate" },
    { header: "Action", field: "action" },
  ];


  const getUserTransaction = async () => {
    try {
      setIsLoading(true);
      setTransactionData([]);
      const {data} = await axisoReqWithAuthToken.get("/client/getBetTransaction?status=all");
      if(data.betHistory)
        data.betHistory.forEach((bet, idx) => {
          const timestamp = new Date(bet.timeStamp);
          setTransactionData(prev => [...prev, {no: idx+1, leagueName: bet.leagueName, matchName: `${bet.teamAName} VS ${bet.teamBName}`, date: `${timestamp.getDate().toString().padStart(2, "0")}-${(timestamp.getMonth()+1).toString().padStart(2, "0")}-${timestamp.getFullYear()} ${getTimeAMPMFormat(timestamp)}`, betTeam: bet.betTeamName, amount: bet.amountOfBet, rate: bet.tossRate, action: bet.betStatus }])
        });
      setIsLoading(false);
    } catch(error) {
        console.log(error);
        setIsLoading(false);
        toast.error(error.response.data.message, {
          className: "toast-message",
        });
    }
  }

  useEffect(() => {
    getUserTransaction();
  }, []);

  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && <div className="w-full max-sm:w-[96vw] mx-auto bg-white p-4 mt-14 max-sm:mt-0 h-fit overflow-hidden rounded-xl">
        <CreateTable
          columns={columns}
          data={getActivePageData(transactionData, entryStart, entryEnd)}
          tableName={"Toss Transaction Report"}
        />
        <PaginationComponent
          numberOfEntries={transactionData.length}
          entriesPerPage={entriesPerPage}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>}
    </>
  );
}
