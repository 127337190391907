import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import {axisoReqWithAuthToken} from "../../Utils";

export default function UserTableComponent({ tableName, columns, data, setIsblockListUpdated }) {
  const [searchbox, setSearchbox] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const unblockUserHandler = async (e) => {
    if(e.target.innerHTML!=="Unblock") return;
    const username = e.target.dataset.username;
    try {
      setIsLoading(true);
      const {data} = await axisoReqWithAuthToken.post("/admin/unblockUser", {userName: username});
      setIsblockListUpdated(prev => !prev);
      setIsLoading(false);
      toast.success(data.message, { className: "toast-message" });
    } catch(error) {
      setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
      }
    }
  };

  useEffect(() => {
    const x = setTimeout(() => {
      setFilteredData(
        data.filter((item) =>
          item.username.toLowerCase().startsWith(searchbox.toLowerCase())
        )
      );
    }, 1000);

    return () => {
      clearTimeout(x);
    };
  }, [searchbox, setFilteredData, data]);

  const renderTableBody = () => {
    if (!filteredData.length)
      return (
        <tr key={0}>
          <td key={0} colspan={"100%"}>
            <span className="text-gray-600 font-medium text-xl p-3 tracking-wide flex justify-center align-center">
              No Data
            </span>
          </td>
        </tr>
      );

    return filteredData.map((row, rowIndex) => (
      <>
        <tr
          key={rowIndex}
          className={`${
            rowIndex % 2 ? "bg-gray-200" : "bg-white"
          } whitespace-nowrap border border-black `}
        >
          {columns.map((column, colIndex) => (
            <td
              key={colIndex}
              data-username={row["username"]}
              className={"p-2 text-center whitespace-nowrap text-sm font-semibold text-gray-700 border border-black cursor-pointer " + `${isLoading && "pointer-events-none"}`}
              onClick={unblockUserHandler}
            >
              {row[column.toLowerCase()]}
            </td>
          ))}
        </tr>
      </>
    ));
  };

  return (
    <div className="w-full max-sm:w-[96vw] max-sm:mx-auto bg-white mt-8 p-4 pb-0 max-sm:pt-0 h-fit overflow-hidden rounded-xl">
      <div className="flex justify-between items-center pb-4 max-md:flex-col max-md:gap-1 max-md:mt-2">
        <h1 className="font-bold text-2xl max-md:text-xl">{tableName}</h1>
        <input
          type="text"
          placeholder="Username"
          className="border-2 border-slate-500 pl-2 pr-1 rounded-xl max-md:rounded-md max-md:w-4/12 max-md:text-sm self-end"
          value={searchbox}
          onChange={(e) => setSearchbox(e.target.value)}
        />
      </div>
      <div className="inline-block w-full h-fit rounded-xl overflow-x-hidden border border-slate-500">
        <table className="w-full border border-black">
          <thead className="bg-red-500">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="border border-black whitespace-nowrap p-2 font-bold text-white tracking-wider text-center"
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="relative divide-y divide-gray-200">
            {renderTableBody()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
