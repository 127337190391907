import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { axisoReqWithAuthToken, delay } from "../Utils";

export default function ChangePassword() {
  const DEFAULT_PASSWORD = process.env.REACT_APP_DEFAULT_PASSWORD;

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  const { username, isFirstTimeuser } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (isFirstTimeuser) {
      toast.warn("First time user, please change your password", {
        className: "toast-message",
        toastId: 1
      });
    }
  }, []);

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword === DEFAULT_PASSWORD) {
      toast.error("Password not allowed", { className: "toast-message" });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match", { className: "toast-message" });
      return;
    }

    try {
      setIsLoading(true);
      await axisoReqWithAuthToken.post("/changePassword", {
        userName: username,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      });
      toast.success("Password changed successfully. Logging out user", {
        className: "toast-message",
      });
      setNewPassword("");
      setConfirmPassword("");
      await delay(500);
      await axisoReqWithAuthToken.post("/logout");
      localStorage.clear();
      await delay(500);
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  return (
    <div>
      <div className=" bg-gray-100 p-4">
        <div className="bg-white mt-8 mx-4 my-6 p-8 rounded-xl">
          <h1 className="px-4 pb-6 text-2xl  font-bold">Change Password</h1>

          <form>
            <div className="grid grid-col-2 gap-4 px-4 pb-6">
              <div className="grid grid-col-2 gap-2 items-center">
                <label
                  htmlFor="new_password"
                  className="text-lg font-semibold "
                >
                  New Password:
                </label>
                <div className="relative">
                  <input
                    placeholder="Password"
                    className="w-full p-1 rounded-md ring-2 ring-red-500 focus:outline-none "
                    type={newPasswordVisible ? "text" : "password"}
                    id="new_password"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    required
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer "
                    onClick={() => setNewPasswordVisible((prev) => !prev)}
                  >
                    {newPasswordVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                </div>
              </div>

              <div className="grid grid-col-2 gap-2 items-center">
                <label
                  htmlFor="confirm_password"
                  className="text-lg font-semibold"
                >
                  Confirm Password:
                </label>
                <div className="relative">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    id="confirm_password"
                    placeholder="Re-Type Password"
                    className="w-full  p-1 rounded-md ring-2 ring-red-500 focus:outline-none "
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer "
                    onClick={() => setConfirmPasswordVisible((prev) => !prev)}
                  >
                    {confirmPasswordVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className={
                  "bg-red-500 text-white font-semibold  py-2 px-8  rounded-md hover:bg-red-600 focus:outline-none ease-in-out delay-150 " +
                  `${isLoading && "pointer-events-none"}`
                }
                onClick={handleSubmit}
              >
                {isLoading ? "Sending Data..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
