import axios from "axios";

const monthMap = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const userTypes = {
  admin: "admin",
  employee: "employee",
  client: "client",
};

export const getActivePageData = (data, entryStart, entryEnd) => {
  return [...data].slice(entryStart, entryEnd);
};

/**
 * https://bet-app-backend.onrender.com
 * TODO: make the url based on env
 * http://localhost:8000
 */
export const axisoReqWithAuthToken = axios.create({
    baseURL: "https://bet-app-backend.onrender.com",
    headers: {
        authorization: `bearer ${localStorage.getItem("jwtToken")}`,
    },
});

export const getTimeAMPMFormat = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? '0' + hours : hours;
  // appending zero in the start if hours less than 10
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ampm;
};

export const convertDateTimeToISOFormat = (date, time) => {
  const ddMmYyyy = date.split("-");
  const [sHours, minuteAMPM] = time.split(":");
  let hours = +sHours;
  const idxAMPM = minuteAMPM.indexOf("M")-1;
  const minutes = minuteAMPM.slice(0, idxAMPM);
  const AMPM = minuteAMPM.slice(idxAMPM);

  if(AMPM === "PM" && hours<12) hours = hours+12;
  if(AMPM === "AM" && hours===12) hours = hours-12;

  // betStartTime 2023-10-18T22:01:56.000Z

  return `${ddMmYyyy[2]}-${ddMmYyyy[1]}-${ddMmYyyy[0]}T${hours.toString().padStart(2, "0")}:${minutes.padStart(2, "0")}:00.000Z`;

};

export const stringCapitalize = (sentance) => {
  return sentance.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export const dateConvert = (dateTime) => {
  const [date, time] = dateTime.toISOString().split("T");
    const datVal = date.split("-");
    const timeVal = time.split(".")
    return `${monthMap[+datVal[1]-1]} ${datVal[2]} ${datVal[0]} ${timeVal[0]}`;
};

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const convertDateInProperFormat = (date) => {
  const dateParts = date.split(" ")[0].split("-");
  return `${dateParts[2]}-${+dateParts[1]}-${dateParts[0]}`;
};

export const blobToFile = (theBlob, fileName) => {  
  theBlob.name = fileName;
  return theBlob;
}

