import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import UserSettingComponent from "../generalComponents/UserSettingComponent";
import PaginationComponent from "../generalComponents/PaginationComponent";
import { getActivePageData, axisoReqWithAuthToken, delay } from "../../Utils";
import LoadingComponent from "../generalComponents/LoadingComponent";
import { toast } from "react-toastify";
import { userTypes } from "../../Utils";

export default function UserListComponent() {
  const [activePage, setActivePage] = useState(0);
  const [searchbox, setSearchbox] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [settingClicked, setSettingClicked] = useState([]);
  const [isUserDataUpdated, setIsUserDataUpdated] = useState(false);
  
  const navigate = useNavigate();
  
  const entriesPerPage = 7;

  const columns = ["UserName", "Balance", "Exposure", "Setting"];
  const avaliableUserTypes = new Set(Object.values(userTypes));

  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  const getUserList = async () => {
    try {
      setIsLoading(true);
      setUserListData([]);
      const {data} = await axisoReqWithAuthToken.get("/admin/getUserList");
      // console.log(data.userList);
      data.userList.forEach(element => {
        setUserListData(prev => [...prev, {username: element.userName, balance: element.balance, exposure: element.exposure, userType: element.userType}]);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if(error.response.status===401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    getUserList()
  }, [isUserDataUpdated]);

  useEffect(() => {
    const x = setTimeout(() => {
      if(avaliableUserTypes.has(searchbox.toLowerCase())) {
        // console.log("I am here");
        setFilteredData(userListData.filter(item => item.userType===searchbox.toLowerCase()));
        return;
      }
      setFilteredData(
        userListData.filter((item) =>
          item.username.toLowerCase().startsWith(searchbox.toLowerCase())
        )
      );
    }, 1000);

    return () => {
      clearTimeout(x);
    };
  }, [searchbox, setFilteredData, userListData]);

  useEffect(() => {
    setSettingClicked(Array.from({ length: userListData.length }, () => false))
  }, [userListData]);

  const renderTableBody = () => {
    if (!filteredData.length)
      return (
        <div className="col-span-full w-full flex items-center justify-center p-3 border border-t-0 border-black rounded-b-xl">
          <h1>No Data</h1>
        </div>
      );

    return getActivePageData(filteredData, entryStart, entryEnd).map(
      (user, rowNo) => {
        const tableRow = [];
        Object.entries(user).map((entry, idx) => {
          if(entry[0]==="userType") return;
          return tableRow.push(
            <span
              key={`${rowNo}-${idx}`}
              className={
                "text-center border border-black border-t-0 max-sm:text-xs max-sm:p-1 " +
                `${idx === 3 ? "" : "border-r-white"} ${
                  rowNo % 2 ? "bg-gray-200" : "bg-white"
                } ${
                  rowNo === filteredData.length - 1 &&
                  idx === 0 &&
                  "rounded-bl-xl"
                }`
              }
            >
              {entry[1]}
            </span>
          );
        });
        tableRow.push(
          <UserSettingComponent
            key={`${rowNo}-${3}`}
            rowNo={rowNo}
            isLastRow={filteredData.length - 1 === rowNo}
            username={user.username}
            userType={user.userType}
            settingClicked={settingClicked}
            setSettingClicked={setSettingClicked}
            setIsUserDataUpdated={setIsUserDataUpdated}
          />
        );
        return tableRow;
      }
    );
  };

  return (
    <>
    {isLoading && <LoadingComponent />}
      {!isLoading && <div className="w-full max-sm:w-[96vw] max-sm:mx-auto bg-white p-4 sm:mt-8 overflow-y-scroll min-h-fit h-[70vh] rounded-xl">
        <div className="flex justify-between items-center pb-4 max-md:flex-col max-md:gap-1 max-md:mt-2">
          <h1 className="font-bold self-start text-2xl max-md:text-xl">User List</h1>
          <input
            type="text"
            placeholder="Username or UserType"
            className="border-2 border-slate-500 pl-2 pr-1 rounded-xl max-md:rounded-md max-md:w-[10.3rem] max-md:text-sm self-end"
            value={searchbox}
            onChange={(e) => setSearchbox(e.target.value)}
          />
        </div>
        <div className="user-table">
          <div
            className={`grid grid-cols-[2fr_1fr_1fr_min-content] justify-center align-center inline-block w-full`}
          >
            {columns.map((column, idx) => (
              <span
                key={idx}
                className={
                  "px-4 font-bold text-white tracking-wider block text-center border border-black bg-red-500 p-2 max-sm:text-xs max-sm:px-1 " +
                  `${
                    idx === 0 ? "rounded-ss-xl border-r-red-500" : "rounded-none"
                  } ${
                    idx === 3 ? "rounded-se-xl" : "rounded-none"
                  } ${
                    idx === 0 || idx === 3
                      ? "border-black"
                      : "border-r-red-500"
                  }`
                }
              >
                {column}
              </span>
            ))}
            {renderTableBody()}
          </div>
        </div>
        <PaginationComponent
          numberOfEntries={userListData.length}
          entriesPerPage={entriesPerPage}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>}
    </>
  );
}
