import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import Compressor from "compressorjs";
import { toast } from "react-toastify";
import {
    axisoReqWithAuthToken,
    convertDateTimeToISOFormat,
    blobToFile,
} from "../../Utils";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

export default function CreateBetComponent(props) {
    const [team1, setTeam1] = useState(props.teamAName ?? "");
    const [team2, setTeam2] = useState(props.teamBName ?? "");
    const [leagueName, setLeagueName] = useState(props.leagueName ?? "");
    const [sportsType, setSportsType] = useState(props.sportType ?? "default");
    const [tossRate, setTossRate] = useState(props.tossRate ?? null);
    const [poster, setPoster] = useState(props.posterImg ?? null);
    const [startDateValue, setStartDateValue] = useState(
        props.betStartTime ? new Date(props.betStartTime) : new Date()
    );
    const [endDateValue, setEndDateValue] = useState(
        props.betEndDate
            ? new Date(
                  convertDateTimeToISOFormat(props.betEndDate, props.betEndTime)
              )
            : new Date()
    );
    const [isLoading, setIsLoading] = useState(false);

    const isEdit = Object.keys(props).length !== 0;

    const navigate = useNavigate();

    const team1Ref = useRef("");
    const team2Ref = useRef("");
    const tossRateRef = useRef("");
    const sportsTypeRef = useRef("");

    const formHandler = async (e) => {
        e.preventDefault();

        if (!team1) {
            toast.error("Please Enter team name!", {
                className: "toast-message",
            });
            team1Ref.current.focus();
            return;
        }
        if (!team2) {
            toast.error("Please Enter team name!", {
                className: "toast-message",
            });
            team2Ref.current.focus();
            return;
        }
        if (!tossRate) {
            toast.error("Please Enter toss rate!", {
                className: "toast-message",
            });
            tossRateRef.current.focus();
            return;
        }
        if (isNaN(+tossRate)) {
            toast.error("Please Enter toss rate!", {
                className: "toast-message",
            });
            setTossRate(null);
            tossRateRef.current.focus();
            return;
        }

        if (!leagueName) {
            toast.error("Please Enter league name!", {
                className: "toast-message",
            });
            return;
        }

        if (!sportsType || sportsType==="default") {
            toast.error("Please Select Sports Type", {
                className: "toast-message",
            });
            sportsTypeRef.current.focus();
            return;
        }

        if (poster && poster.size > 120000) {
            toast.error("Poster size must be less than 2MB!", {
                className: "toast-message",
            });
            return;
        }

        // make backend call
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("teamAName", team1);
            formData.append("teamBName", team2);
            formData.append("tossRate", tossRate);
            formData.append("posterImg", poster);
            formData.append("leagueName", leagueName);
            formData.append("sportType", sportsType);
            const betId = isEdit ? props._id : null;
            const { data } = await axisoReqWithAuthToken.post(
                `/admin/${
                    isEdit ? "editBet" : "createBet"
                }?betStartTime=${startDateValue}&betEndTime=${endDateValue}&betId=${betId}`,
                formData
            );

            toast.success(data.message, { className: "toast-message" });
            setTeam1("");
            setTeam2("");
            setLeagueName("");
            setSportsType("default");
            setTossRate("");
            setPoster(null);
            setStartDateValue(new Date());
            setEndDateValue(new Date());
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast.error(error.response.data.message, {
                className: "toast-message",
            });
            if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
            }
        }
    };

    const handlePosterUpload = (e) => {
        new Compressor(e.target.files[0], {
            quality: 0.5,
            mimeType: "image/jpeg",
            convertSize: 250000,
            success: (compressedResult) => {
                setPoster(blobToFile(compressedResult, "poster"));
            },
        });
    };

    const renderPoster = () => {
        if (poster.data) {
            return (
                <img
                    src={`data:${poster.type};base64,${btoa(
                        String.fromCharCode(...new Uint8Array(poster.data.data))
                    )}`}
                    alt="poster"
                    className="w-20 h-20 object-cover"
                />
            );
        }
        return (
            <img
                src={URL.createObjectURL(poster)}
                className="w-20 h-20 object-cover"
                alt="poster"
            />
        );
    };

    return (
        <div className="flex align-center justify-center pb-16 md:pb-16">
            <form
                onSubmit={formHandler}
                className="mt-8 max-sm:mt-0 pt-4 pl-8 pr-8 pb-8 flex flex-col align-center justify-center gap-1 w-[90vw] sm:w-[50vw] h-fit bg-white rounded-2xl shadow-[-2px_2px_10px_rgba(0,0,0,0.3)]"
                encType="multipart/form-data"
            >
                <h1 className="text-xl font-semibold flex justify-center">
                    {props.heading ?? "Create Bet"}
                </h1>
                <div>
                    <label
                        htmlFor="team1"
                        className="block text-700 font-semibold mb-1"
                    >
                        Team1
                    </label>
                    <input
                        type="text"
                        placeholder="Team1"
                        required
                        ref={team1Ref}
                        value={team1}
                        className="w-full px-2 py-1  mb-2 rounded-md ring-2 ring-red-500 focus:outline-none"
                        id="team1"
                        onChange={(e) => setTeam1(e.target.value)}
                    />
                </div>
                <div>
                    <label
                        htmlFor="team2"
                        className="block text-700 font-semibold mb-1"
                    >
                        Team2
                    </label>
                    <input
                        type="text"
                        placeholder="Team2"
                        required
                        ref={team2Ref}
                        value={team2}
                        className="w-full px-2 py-1  mb-2 rounded-md ring-2 ring-red-500 focus:outline-none"
                        id="team2"
                        onChange={(e) => setTeam2(e.target.value)}
                    />
                </div>
                <div>
                    <label
                        htmlFor="team2"
                        className="block text-700 font-semibold mb-1"
                    >
                        Tournament Name
                    </label>
                    <input
                        type="text"
                        placeholder="Team2"
                        required
                        ref={team2Ref}
                        value={leagueName}
                        className="w-full px-2 py-1  mb-2 rounded-md ring-2 ring-red-500 focus:outline-none"
                        id="team2"
                        onChange={(e) => setLeagueName(e.target.value)}
                    />
                </div>
                <div className="">
                    <label
                        htmlFor="sportsType"
                        className="block text-base font-medium text-gray-900"
                    >
                        Select Sports Type
                    </label>
                    <select
                        name="sportsType"
                        // defaultValue={"default"}
                        value={sportsType}
                        onChange={(e) => setSportsType(e.target.value)}
                        ref={sportsTypeRef}
                        className="w-full p-1 border-2 border-red-500 border-solid rounded-md focus:outline-red-400 max-lg:w-full"
                    >
                        <option disabled value="default">
                            {"-- select an option --"}
                        </option>
                        <option value="Kabaddi" className="focus:bg-red-500">
                            Kabaddi
                        </option>
                        <option value="Cricket" className="focus:bg-red-500">
                            Cricket
                        </option>
                    </select>
                </div>
                <div>
                    <label
                        htmlFor="tossRate"
                        className="block text-700 font-semibold mb-1"
                    >
                        Toss Rate
                    </label>
                    <input
                        type="number"
                        placeholder="Toss Rate"
                        required
                        ref={tossRateRef}
                        value={tossRate}
                        className="w-full px-2 py-1 mb-2 rounded-md ring-2 ring-red-500 focus:outline-none"
                        id="tossRate"
                        onChange={(e) => setTossRate(e.target.value)}
                    />
                </div>
                <div className="dateTimePicker">
                    <div className="start-time">
                        <span className="block text-700 font-semibold">
                            Start Time
                        </span>
                        <DateTimePicker
                            amPmAriaLabel="Select AM/PM"
                            calendarAriaLabel="Toggle calendar"
                            clearAriaLabel="Clear value"
                            dayAriaLabel="Day"
                            hourAriaLabel="Hour"
                            maxDetail="second"
                            minuteAriaLabel="Minute"
                            monthAriaLabel="Month"
                            nativeInputAriaLabel="Date and time"
                            onChange={setStartDateValue}
                            secondAriaLabel="Second"
                            value={startDateValue}
                            yearAriaLabel="Year"
                        />
                    </div>
                    <div className="end-time">
                        <span className="block text-700 font-semibold">
                            End Time
                        </span>
                        <DateTimePicker
                            amPmAriaLabel="Select AM/PM"
                            calendarAriaLabel="Toggle calendar"
                            clearAriaLabel="Clear value"
                            dayAriaLabel="Day"
                            hourAriaLabel="Hour"
                            maxDetail="second"
                            minuteAriaLabel="Minute"
                            monthAriaLabel="Month"
                            nativeInputAriaLabel="Date and time"
                            onChange={setEndDateValue}
                            secondAriaLabel="Second"
                            value={endDateValue}
                            yearAriaLabel="Year"
                        />
                    </div>
                </div>
                <div className="flex items-center mt-4">
                    <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        name="poster"
                        onChange={handlePosterUpload}
                    />
                    {poster && renderPoster()}
                </div>
                <button
                    type="submit"
                    className={
                        "mt-6 hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " +
                        `${isLoading && "pointer-events-none"}`
                    }
                >
                    {isLoading ? "Sending Data..." : "Submit"}
                </button>
            </form>
        </div>
    );
}
