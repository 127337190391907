import React from "react";
import Login from "./Login";
import SocialMedia from "./SocialMedia";

export default function LogInMainPage({ setIsLoggedIn }) {
  return (
    <div className="bg-gray-100 h-[100vh] w-[100vw] flex items-center justify-center">
      <div className="w-fit h-fit py-4 bg-white flex flex-col items-center justify-center gap-1 px-8 rounded-xl drop-shadow-[0_10px_15px_rgba(0,0,0,0.28)] ">
        <Login
          setIsLoggedIn={setIsLoggedIn}
        />
        <SocialMedia />
      </div>
    </div>
  );
}
