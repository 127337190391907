import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { axisoReqWithAuthToken } from "../../Utils";

// {"_id":"652af2c584ecb914ec5e1cac","teamAName":"India","teamBName":"Pakistan ","tossRate":50,"betEndTime":"10:01 PM","betStartTime":"2023-10-14T19:58:00.000Z","posterLink":"Poster URL","leagueName":"League Name","betResult":"pending","active":true,"expiresAt":"2023-10-14T19:57:57.748Z","__v":0,"betEndDate":"16-09-2023"}

export default function SettleUpComponent({ setShowSettleupCard, matchData }) {
  const expectedResult = [matchData.teamAName, matchData.teamBName, "no result"];

  const [selectedResult, setSelectedResult] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const settleUpBtnHandler = async () => {
    if (!selectedResult.length) {
      toast.error("You have not selected any result", {
        className: "toast-message",
      });
      return;
    }
    try {
      setIsloading(true);
      const {data} = await axisoReqWithAuthToken.post("/admin/settleUpBet", {betId: matchData._id, finalBetResult: selectedResult});
      setIsloading(false);
      toast.success(data.message, {
        className: "toast-message",
      });
  
      setSelectedResult("");
      setShowSettleupCard(false);
      window.location.reload();
    } catch(error) {
      toast.error("Error while settling up bet", {
        className: "toast-message",
      });
      setIsloading(false);setSelectedResult("");
      setShowSettleupCard(false);
    }
  };

  return (
    <>
      <div className="z-10 fixed top-16 left-0 sm:left-64 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm"></div>
      <div className="z-10 fixed flex items-center justify-center ml-[8rem] lg:ml-0 max-sm:ml-0 lg:pr-0 top-0 left-0 text-xl w-full h-full">
        <div className="relative bg-white rounded-lg py-6 px-4 w-[16rem] sm:w-[22rem]">
          <MdClose
            className="text-red-800 absolute top-1 right-2 text-3xl cursor-pointer"
            onClick={() => setShowSettleupCard(false)}
          />
          <h3 className="text-center mb-4 font-medium tracking-wide">
            {matchData.teamAName}{" "}
            {<span className="text-sm font-bold text-red-500">VS</span>}{" "}
            {matchData.teamBName}
          </h3>
          <div className="flex gap-2 items-center justify-center mb-6">
            <label
              htmlFor="result"
              className="text-base font-medium text-gray-900"
            >
              Result
            </label>
            <select
              name="result"
              defaultValue={"default"}
              className="p-1 border-2 border-slate-300 border-solid rounded-md focus:outline-red-400 max-lg:w-full text-base"
              onChange={(e) => setSelectedResult(e.target.value)}
            >
              <option disabled value="default">
                {"-- select an option --"}
              </option>
              {expectedResult.map((result, idx) => (
                <option key={idx} value={result} className="focus:bg-red-500">
                  {result}
                </option>
              ))}
            </select>
          </div>
          {selectedResult.length === 0 && (
            <p className="text-base text-center mb-2 invisible pointer-events-none">
              Place Holder
            </p>
          )}
          {selectedResult.length !== 0 &&
            (selectedResult === expectedResult[2] ? (
              <p className="text-base text-center mb-2">
                Settle up bet with{" "}
                <span className="text-red-700">{expectedResult[2]}</span>?
              </p>
            ) : (
              <p className="text-base text-center mb-2">
                Settle up bet with{" "}
                <span className="text-red-700">{selectedResult}</span> as
                winner?
              </p>
            ))}
          <div className="flex align-center justify-between px-8">
            <button
              onClick={settleUpBtnHandler}
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " + `${isLoading && "pointer-events-none"}`}
            >
              Yes
            </button>
            <button
              onClick={() => setShowSettleupCard(false)}
              className="hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
