import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

export default function FirstTimeUserProtectedRoute({ children }) {
    const navigate = useNavigate();
    const username = localStorage.getItem("username");
    const isFirstTimeUser = localStorage.getItem("isFirstTimeUser");

    useEffect(() => {
      if(isFirstTimeUser) navigate("/changePassword", {
        state: { username, isFirstTimeuser: true },
      });
    }, []);

    return children;
}
