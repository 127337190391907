import React, { useState, useEffect } from "react";
import CreateTable from "./CreateTable";
import PaginationComponent from "./generalComponents/PaginationComponent";
import LoadingComponent from "./generalComponents/LoadingComponent";
import { useNavigate } from "react-router-dom";
import {
    getActivePageData,
    axisoReqWithAuthToken,
    getTimeAMPMFormat,
} from "../Utils";
import { toast } from "react-toastify";

export default function KabaddiComponent() {
    const [activePage, setActivePage] = useState(0);
    const [scheduleData, setScheduleData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const entriesPerPage = 7;

    const columns = [
        { header: "No", field: "no" },
        { header: "Team A", field: "teamA" },
        { header: "Team B", field: "teamB" },
        { header: "League Name", field: "leagueName" },
        { header: "Start Time", field: "starttime" },
        { header: "End Time", field: "endtime" },
        { header: "Rate", field: "rate" },
    ];

    const entryStart = activePage * entriesPerPage;
    const entryEnd = (activePage + 1) * entriesPerPage;

    const getScheduleData = async () => {
        try {
            setIsLoading(true);
            setScheduleData([]);
            const { data } = await axisoReqWithAuthToken.get(
                "/client/getBetSchedule"
            );
            data.kabaddiBetList.forEach((bet, idx) => {
                const startTime = new Date(bet.betStartTime);
                const endTime = new Date(bet.betEndTime);

                setScheduleData((prev) => [
                    ...prev,
                    {
                        no: idx + 1,
                        teamA: bet.teamAName,
                        teamB: bet.teamBName,
                        leagueName: bet.leagueName,
                        date: `${startTime
                            .getDate()
                            .toString()
                            .padStart(2, "0")}-${(startTime.getMonth() + 1)
                            .toString()
                            .padStart(
                                2,
                                "0"
                            )}-${startTime.getFullYear()} ${getTimeAMPMFormat(
                            startTime
                        )}`,
                        starttime: `${startTime
                            .getDate()
                            .toString()
                            .padStart(2, "0")}-${(startTime.getMonth() + 1)
                            .toString()
                            .padStart(
                                2,
                                "0"
                            )}-${startTime.getFullYear()} ${getTimeAMPMFormat(
                            startTime
                        )}`,
                        endtime: `${endTime
                            .getDate()
                            .toString()
                            .padStart(2, "0")}-${(endTime.getMonth() + 1)
                            .toString()
                            .padStart(
                                2,
                                "0"
                            )}-${endTime.getFullYear()} ${getTimeAMPMFormat(
                            endTime
                        )}`,
                        rate: bet.tossRate,
                    },
                ]);
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.message, {
                className: "toast-message",
            });
            if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
            }
        }
    };

    useEffect(() => {
        getScheduleData();
    }, []);

    return (
        <>
            {isLoading && <LoadingComponent />}
            {!isLoading && (
                <div className="w-full max-sm:w-[96vw] mx-auto bg-white p-4 mt-14 max-sm:mt-0 h-fit overflow-hidden rounded-xl">
                    <CreateTable
                        columns={columns}
                        data={getActivePageData(
                            scheduleData,
                            entryStart,
                            entryEnd
                        )}
                        tableName={"Kabaddi Schedule"}
                    />
                    <PaginationComponent
                        numberOfEntries={scheduleData.length}
                        entriesPerPage={entriesPerPage}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                </div>
            )}
        </>
    );
}
