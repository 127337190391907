import React from "react";
import { useLocation } from "react-router-dom";
import CreateBetComponent from "../generalComponents/CreateBetComponent";

export default function EditBetComponent() {
  const location = useLocation();
  const { state } = location;
  state.heading = "Edit Bet";
  return <CreateBetComponent {...state} />;
}
