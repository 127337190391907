import React, { useState } from "react";
import {convertDateInProperFormat} from "../Utils";

const lastWeek = 7 * 24 * 60 * 60 * 1000;
const lastFifteenDays = 15 * 24 * 60 * 60 * 1000;
const lastFortyFiveDays = 45 * 24 * 60 * 60 * 1000;

export default function CreateTable({ columns, data, tableName }) {
  const [selectedDateRange, setSelectedDateRange] = useState("All");
  const dateRanges = [
    "All",
    "Today",
    "Yesterday",
    "Last Week",
    "Last 15 Days",
    "This Month",
    "Last 45 Days",
  ];

  const handleDateRangeChange = (e) => {
    setSelectedDateRange(e.target.value);
  };

  const filterDataByDateRange = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const filteredData = data.filter((row) => {
      const rowDate = new Date(convertDateInProperFormat(row.date));
      rowDate.setHours(0, 0, 0, 0);
      const timeDifference = currentDate - rowDate;
      switch (selectedDateRange) {
        case "Today":
          return (rowDate.getDate()===currentDate.getDate() && rowDate.getMonth()===currentDate.getMonth() && rowDate.getFullYear()===currentDate.getFullYear());
        case "Yesterday":
          return (rowDate.getDate()===currentDate.getDate()-1 && rowDate.getMonth()===currentDate.getMonth() && rowDate.getFullYear()===currentDate.getFullYear());
        case "Last Week":
          return timeDifference <= lastWeek;
        case "Last 15 Days":
          return timeDifference <= lastFifteenDays;
        case "This Month":
          return (
            currentDate.getMonth() === rowDate.getMonth() &&
            currentDate.getFullYear() === rowDate.getFullYear()
          );
        case "Last 45 Days":
          return timeDifference <= lastFortyFiveDays;
        case "All":
          return true;
        default:
          return true;
      }
    });
    return filteredData;
  };

  const filteredData = filterDataByDateRange();

  const renderTableBody = () => {
    if (!filteredData.length)
      return (
        <tr key={0}>
          <td key={0} colspan={"100%"}>
            <span className="text-gray-600 font-medium text-xl p-3 tracking-wide flex justify-center align-center max-sm:text-xs">
              No Data
            </span>
          </td>
        </tr>
      );

    return filteredData.map((row, rowIndex) => (
      <tr key={rowIndex} className={rowIndex % 2 ? "bg-gray-200" : "bg-white"}>
        {columns.map((column, colIndex) => (
          <td
            key={colIndex}
            className="border p-2 text-center whitespace-nowrap text-sm font-semibold text-gray-700 max-sm:text-xs"
          >
            {row[column.field]}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <>
      <div className="flex justify-between items-center max-md:flex-col">
        <h1 className="font-bold pb-4 max-sm:pb-2 max-md:text-xl max-sm:text-lg">
          {tableName}
        </h1>
        <select
          className="mb-4 py-2 px-4 font-semibold border rounded-lg max-md:self-end max-sm:text-xs max-sm:py-1 max-sm:px-3 max-sm:rounded-md"
          value={selectedDateRange}
          onChange={handleDateRangeChange}
        >
          {dateRanges.map((dateRange) => (
            <option key={dateRange} value={dateRange}>
              {dateRange}
            </option>
          ))}
        </select>
      </div>
      <div className="inline-block w-full h-fit rounded-xl overflow-x-scroll">
        <table className="w-[100vw]">
          <thead className="bg-red-500">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="border py-2 font-bold text-white tracking-wider text-center max-sm:text-xs"
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 ">
            {renderTableBody()}
          </tbody>
        </table>
      </div>
    </>
  );
}
