import React, {useState} from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import {useNavigate} from "react-router-dom";
import { axisoReqWithAuthToken } from "../../Utils";

export default function BlockUserComponent({
  setShowBlockUserComponent,
  username,
  setIsUserDataUpdated
}) {

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const blockUserHandler = async () => {
  try {
    setIsLoading(true);
    await axisoReqWithAuthToken.post("/admin/blockUser", {userName: username});
    toast.success(`Successfully blocked ${username}`, {
      className: "toast-message",
    });
    setIsUserDataUpdated(prev => !prev);
    setIsLoading(false);
    setShowBlockUserComponent(false);
  } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if(error.response.status===401) {
        localStorage.clear();
        navigate("/login");
      }
  }
  };

  return (
    <>
      <div className="z-10 fixed top-16 left-0 sm:left-64 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm"></div>
      <div className="z-10 fixed flex items-center justify-center ml-[8rem] lg:ml-0 max-sm:ml-0 lg:pr-0 top-0 left-0 text-xl w-full h-full">
        <div className="relative bg-white rounded-lg py-6 px-4 text-sm sm:text-base text-center w-[16rem] sm:w-[24rem]">
          <MdClose
            className="text-red-800 absolute top-1 right-2 text-3xl cursor-pointer"
            onClick={() => setShowBlockUserComponent(false)}
          />
          <h3 className="text-center mb-1 font-medium tracking-wide mt-2">
            Are you sure you want to block{" "}
            <span className="text-red-500">{username}</span>?
          </h3>
          <div className="flex align-center justify-around mt-4">
            <button
              type="submit"
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " + `${isLoading && "pointer-events-none"}`}
              onClick={blockUserHandler}
            >
              Yes
            </button>
            <button
              type="submit"
              className="hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn"
              onClick={() => setShowBlockUserComponent(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
