import React from "react";
import { MdClose } from "react-icons/md";

export default function CancelBetComponent({
  setIsCancelBtnClicked,
  cancelBetHandler,
  bettingTeam,
  betAmount,
  isCancelBetResponseLoading
}) {
  return (
    <>
      <div className="z-10 fixed top-24 left-0 sm:left-64 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm"></div>
      <div className="z-10 fixed flex items-center justify-end lg:justify-center pr-8 lg:pr-0 top-0 left-0 text-xl w-full h-full sm:pl-20">
        <div className="relative bg-white rounded-lg p-8 mx-8 sm:mx-0">
          <MdClose
            className="text-red-800 absolute top-1 right-2 text-3xl cursor-pointer"
            onClick={() => setIsCancelBtnClicked(false)}
          />
          <p className="mb-4 max-sm:text-sm">Are you sure you want to cancel bet on <span className="text-purple-700 font-semibold">{bettingTeam}</span> of amount <span className="text-purple-700 font-semibold">{betAmount}</span>?</p>
          <div className="flex align-center justify-center gap-4 px-8">
            <button
              onClick={cancelBetHandler}
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn max-sm:text-sm max-sm:py-0 max-sm:px-2 " + `${isCancelBetResponseLoading && "pointer-events-none"}`}
            >
              Yes
            </button>
            <button
              onClick={() => setIsCancelBtnClicked(false)}
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn max-sm:text-sm max-sm:py-0 max-sm:px-2 " + `${isCancelBetResponseLoading && "pointer-events-none"}`}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
