import React from "react";
import {Navigate, useLocation} from "react-router-dom"
import { userTypes } from "../../Utils";

export default function EmployeeProtectedRoute({children}) {
  const userType = localStorage.getItem("userType");
  const location = useLocation();
  if(userType===userTypes.employee) return <Navigate to="/admin" state={{from: location}} replace />;
  return children;
  
}