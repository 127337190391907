import React, { useState, useRef, useEffect } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { delay, userTypes } from "../Utils";
import ironManLogo from "../images/ironManLogo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Login({ setIsLoggedIn }) {
    const DEFAULT_PASSWORD = process.env.REACT_APP_DEFAULT_PASSWORD;

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const usernameRef = useRef("");
    const passwordRef = useRef("");

    const navigate = useNavigate();

    useEffect(() => {
        const localStorageUserToken = localStorage.getItem("jwtToken");
        const localStorageUserType = localStorage.getItem("userType");
        if (!localStorageUserToken) return;
        localStorageUserType === userTypes.client
            ? navigate("/")
            : navigate("/admin");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const username = e.target[0].value.trim();
        const password = e.target[1].value.trim();

        if (!username) {
            toast.error("username cannot be null", {
                className: "toast-message",
            });
            usernameRef.current.value = "";
            usernameRef.current.focus();
            return;
        }
        if (!password) {
            toast.error("password cannot be null", {
                className: "toast-message",
            });
            passwordRef.current.value = "";
            passwordRef.current.focus();
            return;
        }
        try {
            setIsLoading(true);
            /**
             * https://bet-app-backend.onrender.com/login
             * TODO: Make changes based on env
             * http://localhost:8000/login
             */
            const { data } = await axios.post(
                "https://bet-app-backend.onrender.com/login",
                {
                    userName: username,
                    password: password,
                }
            );
            setIsLoading(false);
            localStorage.setItem("username", data.userName);
            localStorage.setItem("jwtToken", data.token);
            localStorage.setItem("userType", data.userType);
            localStorage.setItem("loginTime", Date());

            // For first time login. Redirect to change password page.
            if (password === DEFAULT_PASSWORD) {
                localStorage.setItem("isFirstTimeUser", true);
                navigate("/changePassword", {
                    state: { username, isFirstTimeuser: true },
                });
            }

            setIsLoggedIn(true);

            toast.success(data.message, {
                className: "toast-message",
            });
            window.location.reload();
        } catch (error) {
            console.log(`ERROR ${JSON.stringify(error.response)}`);
            setIsLoading(false);
            toast.error(error.response.data.message, {
                className: "toast-message",
            });
        }
    };

    useEffect(() => {
        // TODO discuss about having isAuthenticated api
        if (localStorage.getItem("jwtToken")) setIsLoggedIn(true);
    }, [setIsLoggedIn]);

    return (
        <>
            <img
                src={ironManLogo}
                alt="ironManLogo"
                className="w-20 h-20 object-cover rounded-full drop-shadow-[0_7px_5px_rgba(255,0,0,0.5)]"
            />
            <div className="text-2xl font-semibold ">Welcome back</div>
            <div className="text-gray-500 font-semibold ">
                Please enter your details to sign in.
            </div>
            <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-y-4"
            >
                <div className="flex flex-col gap-y-1">
                    <label
                        htmlFor="username"
                        className="block  text-700 font-semibold"
                    >
                        Username
                    </label>
                    <input
                        type="text"
                        placeholder="Username"
                        required
                        className=" w-full py-1 px-2 rounded-md ring-2 ring-red-500 focus:outline-none"
                        id="username"
                        ref={usernameRef}
                    />
                </div>
                <div className="flex flex-col gap-y-2">
                    <div className="flex flex-col gap-y-1">
                        <label
                            htmlFor="password"
                            className="block text-700 font-semibold"
                        >
                            Password
                        </label>
                        <div className="relative ">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Password"
                                required
                                className="w-full p-1 px-2 rounded-md ring-2 ring-red-500 focus:outline-none"
                                id="password"
                                ref={passwordRef}
                            />
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                onClick={() =>
                                    setPasswordVisible(!passwordVisible)
                                }
                            >
                                {passwordVisible ? (
                                    <EyeOutlined />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    type="submit"
                    className={
                        "flex items-center justify-center w-full bg-red-500 text-center text-white font-semibold py-2 rounded-md hover:bg-red-600 focus:outline-none ease-in-out delay-150" +
                        `${isLoading && "pointer-events-none cursor-auto"}`
                    }
                >
                    {isLoading ? (
                        <span className="login-loading">Loading...</span>
                    ) : (
                        "Submit"
                    )}
                </button>
            </form>
        </>
    );
}
