import React, { useState, useEffect, useRef } from "react";
import {useNavigate} from "react-router-dom";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import {axisoReqWithAuthToken} from "../../Utils";

export default function BankDepositComponent({
  setShowDepositComponent,
  username,
  setIsUserDataUpdated
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [balance, setBalance] = useState(null);

  const navigate = useNavigate();

  const depositInputRef = useRef("");

  const depositAmountHandler = async (e) => {
    e.preventDefault();
    const depositAmountValue = +e.target[0].value;
    if (depositAmountValue <= 0) {
      toast.error("Deposit amount must be a positive number", {
        className: "toast-message",
      });
      depositInputRef.current.value = "";
      depositInputRef.current.focus();

      return;
    }
    try {
      setIsLoading(true);
      const {data} = await axisoReqWithAuthToken.post("/admin/depositAmountIntoWallet", {userName: username, amount: depositAmountValue});
      setIsLoading(false);
      toast.success(data.message, { className: "toast-message" });
      setIsUserDataUpdated(prev => !prev);
      setShowDepositComponent(false);
    } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  };

  const getUserWalletDetails = async () => {
    try {
      setIsBalanceLoading(true);
      const {data} = await axisoReqWithAuthToken.get(`/client/wallet?userName=${username}`);
      setBalance(data.balance);
      setIsBalanceLoading(false);
    } catch(error) {
      setIsBalanceLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  };

  useEffect(() => {
    getUserWalletDetails();
  }, []);

  return (
    !isBalanceLoading && <>
      <div className="z-10 fixed top-16 left-0 sm:left-64 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm"></div>
      <div className="z-10 fixed flex items-center justify-center ml-[8rem] lg:ml-0 max-sm:ml-0 lg:pr-0 top-0 left-0 text-xl w-full h-full">
        <div className="relative bg-white rounded-lg py-6 px-4 text-sm sm:text-base text-center w-fit">
          <MdClose
            className="text-red-800 absolute top-1 right-2 text-3xl cursor-pointer"
            onClick={() => setShowDepositComponent(false)}
          />
          <h3 className="text-center mb-1 font-medium tracking-wide mt-2">
            Bank Deposit
          </h3>
          <p>
            Current deposit in <span className="text-red-500">{username}</span>
            's account is: <span className="text-red-500">{balance}</span>
          </p>
          <form
            className="flex flex-col gap-4 mt-4"
            onSubmit={depositAmountHandler}
          >
            <div className="flex gap-2 align-center justify-center">
              <label htmlFor="deposit">Deposit Amount:</label>
              <input
                type="number"
                placeholder="Enter Amount"
                required
                ref={depositInputRef}
                className="w-6/12 pl-2 rounded-md ring-1 ring-slate-300 focus:outline-none"
                id="deposit"
              />
            </div>
            <button
              type="submit"
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " + `${isLoading && "pointer-events-none"}`}
            >
              {isLoading?"Sending Data...":"Deposit"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
