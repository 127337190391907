import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import MarketAnalysisCard from "../generalComponents/MarketAnalysisCard";
import {axisoReqWithAuthToken} from "../../Utils";
import LoadingComponent from "../generalComponents/LoadingComponent";
import { toast } from "react-toastify";

export default function MarketAnalysisComponent() {
  const [marketAnalysisData, setMarketAnalysisData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getMarketAnalysis = async () => {
    setMarketAnalysisData([]);
    try {
      setIsLoading(true);
      const {data} = await axisoReqWithAuthToken("/admin/getMarketAnalysis");
      data.marketResult.forEach(element => {
        setMarketAnalysisData(prev => [...prev, {leagueName: element.leagueName, team1: element.teamAName, team1Amount: element.teamATotalAmount, team2: element.teamBName, team2Amount: element.teamBTotalAmount}]);
      });
      setIsLoading(false);
    } catch(error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }

  }

  useEffect(() => {
    getMarketAnalysis();
  }, []);

  return (
    <>
    {isLoading && <LoadingComponent />}
      {!isLoading && <div className="pb-10">
        <h1 className="font-bold text-center bg-white p-2 rounded-lg mt-6 w-full mx-auto max-sm:w-[95vw] tracking-wide text-slate-700 text-lg [word-spacing:0.2rem] shadow-xl">
          MARKET ANALYSIS
        </h1>
        <div className="grid grid-cols-3 max-lg:grid-cols-1 max-xl:grid-cols-2 2xl:grid-cols-4 gap-x-5 gap-y-10 justify-items-center justify-center items-center bg-gray-100 mt-6 mb-16">
          {!marketAnalysisData.length && <h1 className="block w-[80vw] mx-auto text-4xl text-center mt-16">No Match Found!</h1>}
          {marketAnalysisData.map((item, idx) => (
            <MarketAnalysisCard
              key={idx}
              leagueName={item.leagueName}
              team1={item.team1}
              team1Amount={item.team1Amount}
              team2={item.team2}
              team2Amount={item.team2Amount}
            />
          ))}
        </div>
      </div>}
    </>
  );
}
