import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { useParams } from "react-router";
import CreateTable from "../CreateTable";
import LoadingComponent from "../generalComponents/LoadingComponent";
import PaginationComponent from "../generalComponents/PaginationComponent";
import {getActivePageData, axisoReqWithAuthToken, getTimeAMPMFormat} from "../../Utils";
import { toast } from "react-toastify";

export default function UserBetHistoryComponent() {
  const { username } = useParams();

  const [userBetHistory, setUserBetHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const navigate = useNavigate();

  const entriesPerPage = 7;
  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  const columns = [
    {header: "No", field: "no"},
    { header: "League Name", field: "leagueName" },
    { header: "Match Name", field: "matchName" },
    { header: "Date & Time", field: "date" },
    { header: "Bet Team", field: "betTeam" },
    { header: "Amount", field: "stake" },
    { header: "Rate", field: "rate" },
    {header: "Ip Address", field: "ipAddress"},
    { header: "Action", field: "action" }
  ];

  const getUserBetHistory = async () => {
    try {
      setIsLoading(true);
      setUserBetHistory([]);
      const {data} = await axisoReqWithAuthToken.get(`/client/getBetTransaction?userName=${username}&status=all`);
      if(data.betHistory) {
        data.betHistory.forEach((bet, idx) => {
          const timestamp = new Date(bet.timeStamp);
          setUserBetHistory(prev => [...prev, {no: idx+1, leagueName: bet.leagueName, matchName: `${bet.teamAName} VS ${bet.teamBName}`, date: `${timestamp.getDate().toString().padStart(2, "0")}-${(timestamp.getMonth()+1).toString().padStart(2, "0")}-${timestamp.getFullYear()} ${getTimeAMPMFormat(timestamp)}`, betTeam: bet.betTeamName, stake: bet.amountOfBet, rate: bet.tossRate, ipAddress: bet.ipAddress, action: bet.betStatus }]);
        });
      }
      setIsLoading(false);
    } catch(error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  };

  useEffect(() => {
    getUserBetHistory();
  }, []);

  return (
    <>
      {isLoading && <LoadingComponent />}
      {!isLoading && <div className="w-full bg-white mt-8  my-4 p-4 h-fit overflow-hidden p-2 rounded-xl">
        <CreateTable
          columns={columns}
          data={getActivePageData(userBetHistory, entryStart, entryEnd)}
          tableName={`Bet History Report For ${username}`}
        />
        <PaginationComponent
          numberOfEntries={userBetHistory.length}
          entriesPerPage={entriesPerPage}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>}
    </>
  );
}
