import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateCard from "./CreateCard";
import { userTypes } from "../Utils";
import { axisoReqWithAuthToken, getTimeAMPMFormat } from "../Utils";
import LoadingComponent from "./generalComponents/LoadingComponent";
import { toast } from "react-toastify";

const updatedMatchDataList = [];
const updatedExpiredDataList = [];
const betIdSet = new Set();

export default function CardComp({
    isLoggedIn,
    setIsLoggedIn,
    balanceAndExposure,
    setBalanceAndExposure,
}) {
    const userType = localStorage.getItem("userType");

    const [selectedTeamAValue, setSelectedTeamAValue] = useState(null);
    const [selectedTeamBValue, setSelectedTeamBValue] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [matchDataList, setMatchDataList] = useState([]);
    const [expiredMatches, setExpiredMatches] = useState([]);
    const [teamAList, setTeamAList] = useState([]);
    const [teamBList, setTeamBList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        matchDataList.forEach((match) => {
            setTeamAList((prev) => [...prev, match.teamAName]);
            setTeamBList((prev) => [...prev, match.teamBName]);
        });

        return () => {
            setTeamAList([]);
            setTeamBList([]);
        };
    }, [matchDataList, setTeamAList, setTeamBList]);

    const getAllBets = async () => {
        if (!isLoggedIn) return;
        try {
            setIsLoading(true);
            const { data } = await axisoReqWithAuthToken.get(
                `/${
                    userType === userTypes.client ? "client" : "admin"
                }/getAllBets`
            );
            setIsLoading(false);
            setMatchDataList([]);
            setExpiredMatches([]);
            betIdSet.clear();
            if (userType === userTypes.client)
                data.history.forEach((bet) => {
                    betIdSet.add(bet._id);
                    const date = new Date(bet.betEndTime);
                    setMatchDataList((prev) => [
                      {
                          ...bet,
                          betEndDate: `${date
                              .getDate()
                              .toString()
                              .padStart(2, "0")}-${(+date.getMonth() + 1)
                              .toString()
                              .padStart(2, "0")}-${date.getFullYear()}`,
                          betEndTime: getTimeAMPMFormat(date),
                      },
                      ...prev,
                    ]);
                });
            else {
                setIsLoading(true);
                const { data: betData } = await axisoReqWithAuthToken(
                    "/admin/getMarketAnalysis"
                );
                setIsLoading(false);
                data.betList.forEach((bet) => {
                    betIdSet.add(bet._id);
                    // Add marketAnalysis data
                    const marketAnalysisForBet = betData.marketResult.find(
                        (marketAnalysisBet) =>
                            marketAnalysisBet.betId === bet._id
                    );
                    bet.teamATotalAmount = marketAnalysisForBet
                        ? marketAnalysisForBet.teamATotalAmount
                        : null;
                    bet.teamBTotalAmount = marketAnalysisForBet
                        ? marketAnalysisForBet.teamBTotalAmount
                        : null;
                    const date = new Date(bet.betEndTime);
                    if (date.valueOf() > new Date().valueOf())
                        setMatchDataList((prev) => [
                            {
                                ...bet,
                                betEndDate: `${date
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}-${(+date.getMonth() + 1)
                                    .toString()
                                    .padStart(2, "0")}-${date.getFullYear()}`,
                                betEndTime: getTimeAMPMFormat(date),
                            },
                            ...prev,
                        ]);
                    else
                        setExpiredMatches((prev) => [
                          {
                              ...bet,
                              betEndDate: `${date
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}-${(+date.getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}-${date.getFullYear()}`,
                              betEndTime: getTimeAMPMFormat(date),
                          },
                            ...prev,
                        ]);
                });
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            toast.error(error.response.data.message, {
                className: "toast-message",
            });
            if (error.response.status === 401) {
                localStorage.clear();
                setIsLoggedIn(false);
                navigate("/login");
            }
        }
    };

    useEffect(() => {
        getAllBets();
    }, [isLoggedIn]);

    useEffect(() => {
        betIdSet.clear();
        matchDataList.forEach((bet) => betIdSet.add(bet._id));
        expiredMatches.forEach((bet) => betIdSet.add(bet._id));
        // TODO add for expired bets as well.
    }, [matchDataList, expiredMatches]);

    useEffect(() => {
        if (!isLoggedIn) return;
        const interval = setInterval(async () => {
            updatedMatchDataList.length = 0;
            updatedExpiredDataList.length = 0;
            try {
                const { data } = await axisoReqWithAuthToken.get(
                    `/${userType}/getAllBets`
                );

                if (userType === userTypes.client)
                    data.history.forEach((bet) => {
                        const date = new Date(bet.betEndTime);
                        updatedMatchDataList.push({
                            ...bet,
                            betEndDate: `${date
                                .getDate()
                                .toString()
                                .padStart(2, "0")}-${(
                                +date.getMonth().toString() + 1
                            ).padStart(2, "0")}-${date.getFullYear()}`,
                            betEndTime: getTimeAMPMFormat(date),
                        });
                    });
                else
                    data.betList.forEach((bet) => {
                        const date = new Date(bet.betEndTime);
                        if (date.valueOf() > new Date().valueOf())
                            updatedMatchDataList.push({
                                ...bet,
                                betEndDate: `${date
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}-${(
                                    +date.getMonth().toString() + 1
                                ).padStart(2, "0")}-${date.getFullYear()}`,
                                betEndTime: getTimeAMPMFormat(date),
                            });
                        else
                            updatedExpiredDataList.push({
                                ...bet,
                                betEndDate: `${date
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}-${(
                                    +date.getMonth().toString() + 1
                                ).padStart(2, "0")}-${date.getFullYear()}`,
                                betEndTime: getTimeAMPMFormat(date),
                            });
                    });

                // Get betIds from match data list.
                matchDataList.forEach((bet) => betIdSet.add(bet._id));

                // Get betIds from expired matches list.
                expiredMatches.forEach((bet) => betIdSet.add(bet._id));

                updatedMatchDataList.splice(
                    0,
                    updatedMatchDataList.length,
                    ...updatedMatchDataList.filter(
                        (bet) => !betIdSet.has(bet._id)
                    )
                );
                setMatchDataList((prev) => [...updatedMatchDataList, ...prev]);
                updatedExpiredDataList.splice(
                    0,
                    updatedExpiredDataList.length,
                    ...updatedExpiredDataList.filter(
                        (bet) => !betIdSet.has(bet._id)
                    )
                );
                setExpiredMatches((prev) => [
                    ...prev,
                    ...updatedExpiredDataList,
                ]);
            } catch (error) {
                // TODO: revisit this.
                console.log(error);
            }
        }, 60000);

        return () => clearInterval(interval);
    }, [isLoggedIn]);

    const isAdmin = userType === userTypes.client ? false : true;

    useEffect(() => {
        if (
            (!selectedTeamAValue && !selectedTeamBValue) ||
            (selectedTeamAValue === "show all" &&
                selectedTeamBValue === "show all")
        )
            return setSelectedTeams(matchDataList);

        if (
            selectedTeamAValue &&
            (!selectedTeamBValue || selectedTeamBValue === "show all")
        )
            return setSelectedTeams(
                matchDataList.filter(
                    (match) => match.teamAName === selectedTeamAValue
                )
            );
        if (
            selectedTeamBValue &&
            (!selectedTeamAValue || selectedTeamAValue === "show all")
        )
            return setSelectedTeams(
                matchDataList.filter(
                    (match) => match.teamBName === selectedTeamBValue
                )
            );

        if (selectedTeamAValue && selectedTeamBValue)
            return setSelectedTeams(
                matchDataList.filter(
                    (match) =>
                        match.teamAName === selectedTeamAValue &&
                        match.teamBName === selectedTeamBValue
                )
            );

        setSelectedTeams(matchDataList);
    }, [matchDataList, selectedTeamAValue, selectedTeamBValue]);

    const displaySelectedTeams = () => {
        if (!selectedTeams.length)
            return (
                <h1 className="block w-[80vw] mx-auto text-4xl max-sm:text-2xl text-center">
                    No Matches Found!
                </h1>
            );

        return selectedTeams.map((matchData, index) => (
            <CreateCard
                key={index}
                matchData={matchData}
                isAdmin={isAdmin}
                isExpired={false}
                balanceAndExposure={balanceAndExposure}
                setBalanceAndExposure={setBalanceAndExposure}
            />
        ));
    };

    const displayExpiredMatches = () => {
        return expiredMatches.map((matchData, index) => (
            <CreateCard
                key={index}
                matchData={matchData}
                isAdmin={isAdmin}
                isExpired={true}
                balanceAndExposure={balanceAndExposure}
                setBalanceAndExposure={setBalanceAndExposure}
            />
        ));
    };

    return (
        <>
            {isLoading && <LoadingComponent />}
            {!isLoading && (
                <div className={"relative"}>
                    <div className={`mt-8 p-2 ${isAdmin ? "" : "pt-6"}`}>
                        {/* {matchDataList.length > 0 && (      // TODO: Commenting on owner request
              <SearchBarComponent
                teamAList={teamAList}
                teamBList={teamBList}
                setSelectedTeamAValue={setSelectedTeamAValue}
                setSelectedTeamBValue={setSelectedTeamBValue}
              />
            )} */}
                        <div className="h-fit grid grid-cols-3 max-lg:grid-cols-1 max-xl:grid-cols-2 gap-x-5 gap-y-5 justify-items-center pt-4 pb-14 px-4 justify-center bg-gray-100">
                            {displaySelectedTeams()}
                        </div>
                        {isAdmin && expiredMatches.length !== 0 && (
                            <>
                                <h1 className="text-center text-3xl font-bold tracking-wide mb-8">
                                    Expired Bets
                                </h1>
                                <div className="h-fit grid grid-cols-3 max-lg:grid-cols-1 max-xl:grid-cols-2 gap-x-5 gap-y-5 justify-items-center pt-4 pb-14 px-4 justify-center bg-gray-100">
                                    {displayExpiredMatches()}
                                </div>{" "}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
