import React, { useState, useEffect } from "react";
import CreateTable from "./CreateTable";
import PaginationComponent from "./generalComponents/PaginationComponent";
import LoadingComponent from "./generalComponents/LoadingComponent";
import { getActivePageData, axisoReqWithAuthToken, getTimeAMPMFormat } from "../Utils";


export default function Debit() {
  const [activePage, setActivePage] = useState(0);
  const [debitDetailsData, setDebitDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const entriesPerPage = 7;

  const columns = [
    { header: "No", field: "no" },
    { header: "Date", field: "date" },
    {header: "Description", field: "description"},
    {header: "Bet Team", field: "team"},
    { header: "Debit", field: "debit"},
    {header: "Balance", field: "balance"},
  ];

  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  const getUserDebits = async () => {
    try {
      setIsLoading(true);
      setDebitDetailsData([]);
      const {data}  = await axisoReqWithAuthToken.get("/client/getPassbookDetails");
      const debitPassbookData = data.passbookDetails.filter(detail => detail.transactionType==="debit")
      debitPassbookData.forEach((details, idx) => {
        const timestamp = new Date(details.timeStamp);
        setDebitDetailsData((prev) => [
          ...prev,
          {
            no: idx + 1,
            date: `${timestamp.getDate().toString().padStart(2, "0")}-${(
              timestamp.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${timestamp.getFullYear()} ${getTimeAMPMFormat(
              timestamp
            )}`,
            description: details.transactionDescription,
            team: details.betTeamName,
            debit: details.transactionAmount,
            balance: details.balance,
          },
        ]);
      });
      setIsLoading(false);
    } catch(error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    getUserDebits();
  }, []);

  return (
    <>
    {isLoading && <LoadingComponent />}
    {!isLoading && <div className="w-full max-sm:w-[96vw] mx-auto bg-white p-4 mt-14 max-sm:mt-0 h-fit overflow-hidden rounded-xl">
      <CreateTable
        columns={columns}
        data={getActivePageData(debitDetailsData, entryStart, entryEnd)}
        tableName={"Deposit Transaction Report"}
      />
      <PaginationComponent
        numberOfEntries={debitDetailsData.length}
        entriesPerPage={entriesPerPage}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>}
    </>
  );
}
