import React, {useState, useEffect} from "react";
import PaginationComponent from "../generalComponents/PaginationComponent";
import LoadingComponent from "../generalComponents/LoadingComponent";
import {useNavigate} from "react-router-dom";
import { useParams } from "react-router";
import CreateTable from "../CreateTable";
import {getActivePageData, axisoReqWithAuthToken, getTimeAMPMFormat} from "../../Utils";
import { toast } from "react-toastify";


export default function UserPendingBetsComponent() {
  const { username } = useParams();

  const [pendingBetsList, setPendingBetsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const navigate = useNavigate();

  const columns = [
    {header: "No", field: "no"},
    { header: "League Name", field: "leagueName" },
    { header: "Match Name", field: "matchName" },
    { header: "Date & Time", field: "date" },
    { header: "Bet Team", field: "betTeam" },
    { header: "Amount", field: "stake" },
    { header: "Rate", field: "rate" },
    { header: "Action", field: "action" }
  ];

  const entriesPerPage = 7;
  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  const data = [
    {
      leagueName: "World Cup",
      matchName: "India Vs Pakistan",
      rate: 2.56,
      stake: 9999999,
      betDate: "Saturday, 7 Oct 2023, 11:00",
    },
    {
      leagueName: "World Cup",
      matchName: "India Vs Pakistan",
      rate: 2.56,
      stake: 9999999,
      betDate: "Saturday, 7 Oct 2023, 11:00",
    },
    {
      leagueName: "World Cup",
      matchName: "India Vs Pakistan",
      rate: 2.56,
      stake: 9999999,
      betDate: "Saturday, 7 Oct 2023, 11:00",
    },
    {
      leagueName: "World Cup",
      matchName: "India Vs Pakistan",
      rate: 2.56,
      stake: 9999999,
      betDate: "Saturday, 7 Oct 2023, 11:00",
    },
    {
      leagueName: "World Cup",
      matchName: "India Vs Pakistan",
      rate: 2.56,
      stake: 9999999,
      betDate: "Saturday, 7 Oct 2023, 11:00",
    },
    {
      leagueName: "World Cup",
      matchName: "India Vs Pakistan",
      rate: 2.56,
      stake: 9999999,
      betDate: "Saturday, 7 Oct 2023, 11:00",
    },
  ];

  const getUserPendingBets = async () => {
    try {
        setIsLoading(true);
        setPendingBetsList([]);
        const {data} = await axisoReqWithAuthToken.get(`/client/getBetTransaction?userName=${username}&status=pending`);
        if(data.pendingBets) {
          data.pendingBets.forEach((bet, idx) => {
            console.log(bet);
            const timestamp = new Date(bet.timeStamp);
            setPendingBetsList(prev => [...prev, {no: idx+1, leagueName: bet.leagueName, matchName: `${bet.teamAName} VS ${bet.teamBName}`, date: `${timestamp.getDate().toString().padStart(2, "0")}-${(timestamp.getMonth()+1).toString().padStart(2, "0")}-${timestamp.getFullYear()} ${getTimeAMPMFormat(timestamp)}`, betTeam: bet.betTeamName, stake: bet.amountOfBet, rate: bet.tossRate, action: bet.betStatus }]);
          });
        }
        setIsLoading(false);
    } catch(error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  }

  useEffect(() => {
    getUserPendingBets();
  }, []);

  return (
    <>
    {isLoading && <LoadingComponent />}
      {!isLoading && <div className="w-full bg-white mt-8  my-4 p-4 h-fit overflow-hidden p-2 rounded-xl">
        <CreateTable
          columns={columns}
          data={getActivePageData(pendingBetsList, entryStart, entryEnd)}
          tableName={`Pending Bets Report For ${username}`}
        />
        <PaginationComponent
          numberOfEntries={pendingBetsList.length}
          entriesPerPage={entriesPerPage}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>}
    </>
  );
}
