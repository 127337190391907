import React, {useState} from "react";
import { axisoReqWithAuthToken } from "../../Utils";
import { toast } from "react-toastify";

export default function ToggleComponent({ enableToggle, setEnableToggle, betId }) {

  const [isLoading, setIsLoading] = useState(false);

  const toggleBtnHandler = async () => {
    if(enableToggle) {
      try {
        setIsLoading(true);
        const {data} = await axisoReqWithAuthToken.post("/admin/enableBet", {betId});
        setIsLoading(false);
        setEnableToggle(prev => !prev);
        toast.success(data.message,{ className: "toast-message",});
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error("Failed to disable bet",{ className: "toast-message",});
      }
    } else {
        try {
          setIsLoading(true);
          const {data} = await axisoReqWithAuthToken.post("/admin/disableBet", {betId});
          setIsLoading(false);
          setEnableToggle(prev => !prev);
          toast.success(data.message,{ className: "toast-message",});
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          toast.error("Failed to enable bet",{ className: "toast-message",});
        }
    }
  };


  return (
    <div className="absolute top-1 -right-4 flex flex-col items-center justify-center overflow-hidden">
      <div className="flex flex-col">
        <label className="inline-flex relative items-center mr-5 cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={enableToggle}
            readOnly
          />
          <div
            onClick={toggleBtnHandler}
            className={"w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-red-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-500 " + `${isLoading && "pointer-events-none"}`}
          ></div>
        </label>
      </div>
    </div>
  );
}
