import React, { useState, useEffect } from "react";
import CreateTable from "./CreateTable";
import PaginationComponent from "./generalComponents/PaginationComponent";
import LoadingComponent from "./generalComponents/LoadingComponent";
import {useNavigate} from "react-router-dom";
import { getActivePageData, axisoReqWithAuthToken, getTimeAMPMFormat } from "../Utils";
import { toast } from "react-toastify";


export default function Credit() {
  const [activePage, setActivePage] = useState(0);
  const [creditDetailsData, setCreditDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const entriesPerPage = 7;

  const columns = [
    { header: "No", field: "no" },
    { header: "Date", field: "date" },
    {header: "Description", field: "description"},
    {header: "Bet Team", field: "team"},
    { header: "Credit", field: "credit"},
    {header: "Balance", field: "balance"},
  ];

  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  const getUserCredits = async () => {
    try {
      setIsLoading(true);
      setCreditDetailsData([]);
      const {data}  = await axisoReqWithAuthToken.get("/client/getPassbookDetails");
      const creditPassbookData = data.passbookDetails.filter(detail => detail.transactionType==="credit")
      creditPassbookData.forEach((details, idx) => {
        const timestamp = new Date(details.timeStamp);
        setCreditDetailsData(prev => [...prev, {no: idx+1, date: `${timestamp.getDate().toString().padStart(2, "0")}-${(timestamp.getMonth()+1).toString().padStart(2, "0")}-${timestamp.getFullYear()} ${getTimeAMPMFormat(timestamp)}`, description: details.transactionDescription, team: details.betTeamName, credit: details.transactionAmount, balance: details.balance}])
      });
      setIsLoading(false);
    } catch(error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  }

  useEffect(() => {
    getUserCredits();
  }, []);

  return (
    <>
    {isLoading && <LoadingComponent />}
    {!isLoading && <div className="w-full max-sm:w-[96vw] mx-auto bg-white p-4 mt-14 max-sm:mt-0 h-fit overflow-hidden rounded-xl">
      <CreateTable
        columns={columns}
        data={getActivePageData(creditDetailsData, entryStart, entryEnd)}
        tableName={"Withdraw Transaction Report"}
      />
      <PaginationComponent
        numberOfEntries={creditDetailsData.length}
        entriesPerPage={entriesPerPage}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>}
    </>
  );
}
