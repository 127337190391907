import React from "react";

export default function Footer() {
  const d = new Date();
  return (
      <div className="px-5 py-2 bg-red-500 flex flex-col sm:flex-row gap-2 sm:justify-between fixed bottom-0 w-full z-20 ">
          <p className="text-white font-normal text-center">
              {`Copyright © ${d.getFullYear()}. Ironman Site All Rights Reserved.`}
          </p>
          <p className="text-white font-bold text-center">
              Hand-crafted & made with ♡
          </p>
      </div>
  );
}
