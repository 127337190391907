import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import {axisoReqWithAuthToken} from "../../Utils";

export default function ResetPasswordComponent({
  setShowResetPasswordComponent,
  username,
}) {
  
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const resetPasswordHandler = async () => {
    try {
      setIsLoading(true);
      const response = await axisoReqWithAuthToken.post("/admin/resetPassword", {userName: username});
      toast.success(`Successfully reset password for ${username}`, {
        className: "toast-message",
      });
      setShowResetPasswordComponent(false);
      setIsLoading(false);
    } catch(error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  };

  return (
    <>
      <div className="z-10 fixed top-16 left-0 sm:left-64 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm"></div>
      <div className="z-10 fixed flex items-center justify-center ml-[8rem] lg:ml-0 max-sm:ml-0 lg:pr-0 top-0 left-0 text-xl w-full h-full">
        <div className="relative bg-white rounded-lg py-6 px-4 text-sm sm:text-base text-center w-[20rem] sm:w-[24rem]">
          <MdClose
            className="text-red-800 absolute top-1 right-2 text-3xl cursor-pointer"
            onClick={() => setShowResetPasswordComponent(false)}
          />
          <h3 className="text-center text-lg font-medium tracking-wide mt-2">
            Reset password for <span className="text-red-500">{username}</span>?
          </h3>
          <p>Default Password: <span className="text-purple-700 font-semibold">12345</span></p>
          <div className="flex align-center justify-around mt-6">
            <button
              type="submit"
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " + `${isLoading && "pointer-events-none"}`}
              onClick={resetPasswordHandler}
            >
              Yes
            </button>
            <button
              type="submit"
              className={"hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " + `${isLoading && "pointer-events-none"}`}
              onClick={() => setShowResetPasswordComponent(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
