import { Link } from "react-router-dom";
import img404 from "../../images/404Img.png";
import { userTypes } from "../../Utils";

export default function ErrorComponent() {
  const userType = localStorage.getItem("userType")
  return (
    <div className="w-[100vw] h-[100vh] p-4 bg-[#cfcfcf] text-black flex flex-col gap-4 justify-around">
      <h1 className="text-7xl text-center pt-8">404</h1>
      <div className="h-fit flex gap-4 items-center justify-around p-4 max-md:flex-col max-md:justify-center">
        <div className="flex flex-col items-center justify-center gap-8">
          <p className="text-4xl max-md:text-2xl text-center">Sorry, either you mistyped the url or we deleted that page, <br/> but let's agree to blame this on you.</p>
          <Link to={userType===userTypes.client? "/": "/admin"} className="text-2xl  max-md:text-xl hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn">Home Page</Link>
        </div>
        <img src={img404} alt="404" />
      </div>
    </div>
  );
}