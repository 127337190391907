import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillGearFill } from "react-icons/bs";
import BankDepositComponent from "../adminComponents/BankDepositComponent";
import BankWithdrawComponent from "../adminComponents/BankWithdrawComponent";
import BlockUserComponent from "../adminComponents/BlockUserComponent";
import ResetPasswordComponent from "../adminComponents/ResetPasswordComponent";
import { userTypes } from "../../Utils";

const userTypeClient = ["Bank Deposit", "Bank Withdraw", "Account Statement", "Block", "Pending Bets", "Bet History", "Reset Password"];
const userTypeAdminOrEmployee = ["Block", "Reset Password"];

export default function UserSettingComponent({
  rowNo,
  isLastRow,
  username,
  userType,
  settingClicked,
  setSettingClicked,
  setIsUserDataUpdated
}) {
  const [settingEffect, setSettingEffect] = useState(false);
  const [showDepositComponent, setShowDepositComponent] = useState(false);
  const [showBankWithdraw, setShowBankWithdraw] = useState(false);
  const [showBlockUserComponent, setShowBlockUserComponent] = useState(false);
  const [showResetPasswordComponent, setShowResetPasswordComponent] =
    useState(false);

  const navigate = useNavigate();

  let userSettingList;
  if(userType===userTypes.client) userSettingList = [...userTypeClient];
  else userSettingList = [...userTypeAdminOrEmployee];

  const settingClickHandler = () => {
    setSettingEffect(true);
    setSettingClicked((prev) =>
      prev.map((item, idx) => (idx === rowNo ? !item : false))
    );
  };

  const settingHandler = (event) => {
    const element = event.target.closest("li");

    if (!element) return;

    const item = element.innerText.split(/\r?\n/)[0];

    if (item === userSettingList[0]) setShowDepositComponent(true);
    else if (item === userSettingList[1]) setShowBankWithdraw(true);
    else if (item === userSettingList[2])
      navigate(`/admin/userList/accountStatement/${username}`);
    else if (item === userSettingList[3]) setShowBlockUserComponent(true);
    else if (item === userSettingList[4])
      navigate(`/admin/userList/pendingBets/${username}`);
    else if (item === userSettingList[5])
      navigate(`/admin/userList/betHistory/${username}`);
    else if (item === userSettingList[6]) setShowResetPasswordComponent(true);

    setSettingClicked((prev) =>
      prev.map((item, idx) => (idx === rowNo ? false : item))
    );
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!settingClicked[rowNo]) return;
      if (
        event.target
          .closest("ul")
          ?.className.split(" ")
          .includes("user-setting")
      )
        return;
      const element = event.target.closest("span");
      if (!element || !element.className.split(" ").includes("user-setting")) {
        setSettingClicked((prev) =>
          prev.map((item, idx) => (idx === rowNo ? false : item))
        );
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [settingClicked, setSettingClicked, rowNo]);

  return (
    <div className="relative">
      {settingClicked[rowNo] && (
        <ul
          className="user-setting absolute top-6 right-0 flex flex-col z-10 bg-white rounded-xl overflow-hidden whitespace-nowrap max-sm:text-xs max-sm:right-0"
          onClick={settingHandler}
        >
          {userSettingList.map((item, idx) => (
            <li
              key={idx}
              className={
                "transition duration-700 ease-in-out border border-slate-600 py-2 px-3 hover:bg-red-500 hover:text-white text-center cursor-pointer max-sm:py-1 max-sm:px-1 " +
                `${idx !== 0 ? "border-t-0" : "rounded-ss-xl rounded-se-xl"} ${
                  idx === userSettingList.length - 1 &&
                  "rounded-es-xl rounded-ee-xl"
                }`
              }
            >
              {item === "Reset Password" ? (
                <>
                  {item}
                  <p className="text-sm">
                    Default:
                    <span className="transition duration-700 ease-in-out text-purple-700 font-semibold ml-1">
                      12345
                    </span>
                  </p>
                </>
              ) : (
                item
              )}
            </li>
          ))}
        </ul>
      )}
      {showDepositComponent && (
        <BankDepositComponent
          setShowDepositComponent={setShowDepositComponent}
          username={username}
          setIsUserDataUpdated={setIsUserDataUpdated}
        />
      )}
      {showBankWithdraw && (
        <BankWithdrawComponent
          setShowBankWithdraw={setShowBankWithdraw}
          username={username}
          setIsUserDataUpdated={setIsUserDataUpdated}
        />
      )}
      {showBlockUserComponent && (
        <BlockUserComponent
          setShowBlockUserComponent={setShowBlockUserComponent}
          username={username}
          setIsUserDataUpdated={setIsUserDataUpdated}

        />
      )}
      {showResetPasswordComponent && (
        <ResetPasswordComponent
          setShowResetPasswordComponent={setShowResetPasswordComponent}
          username={username}
        />
      )}
      <span
        className={
          "user-setting flex align-center justify-center text-center border border-black border-t-0 h-7 py-1 " +
          `${rowNo % 2 ? "bg-gray-200" : "bg-white"} ${
            isLastRow && "rounded-br-xl"
          }`
        }
        onClick={settingClickHandler}
      >
        <BsFillGearFill
          className={
            "text-md text-center text-gray-700 cursor-pointer " +
            `${settingEffect && "setting-animate"}`
          }
          onAnimationEnd={() => setSettingEffect(false)}
        />
      </span>
    </div>
  );
}
